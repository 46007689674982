rect.highcharts-background {
  fill: #151525;
}

path.highcharts-axis-line {
  stroke: #151525;
}

path.highcharts-tick {
  stroke: #151525;
}

path.highcharts-grid-line {
  stroke: #272763;
}
