@import url('https://code.highcharts.com/css/stocktools/gui.css');
@import url('https://code.highcharts.com/css/annotations/popup.css');

html,
body {
  height: 100%;
}

h1,
p {
  /* font-family: Lato; */
}

button {
  background: cornflowerblue;
}

.chart-overflow {
  overflow: visible !important;
}

.DayPickerInput input {
  background-color: #10101c;
}

.DayPickerInput-Overlay {
  background: #10101c;
}

/* Conflict with Bootstrap, not needed after v7.0.1 */
.highcharts-bindings-wrapper * {
  box-sizing: content-box;
}
