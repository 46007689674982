#footer .modal-content {
  width: 95vw;
  height: 93vh;
  background: #19192c;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
#footer .modal-header {
  border-bottom: none !important;
}
#footer .sidebar-sticky {
  text-align: left !important;
  padding-top: 0.4rem;
  font-weight: 600;
}
#footer .sidebar-sticky a {
  color: #fff;
  opacity: 0.2;
  letter-spacing: 1px;
  font-size: 14px;
  letter-spacing: 0.2px;
  padding: 1rem 0 0.8rem 2rem;
}
#footer .sidebar-sticky a.active {
  opacity: 1;
}

#footer .bg-blue {
  color: #007bff !important;
}

#footer .content-read-header h2 {
  background: #19192c;
  padding: 1rem 3rem;
  color: #ffff;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.8px;
}
#footer .content-read h3 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.8px;
}
#footer .sidebar-sticky a:hover,
#footer .sidebar-sticky a:focus {
  cursor: pointer !important;
}
#footer .content-read {
  background: #ffffff;
  color: #000;
  height: 70vh !important;
  overflow-y: scroll !important;
}
#footer .content-read p {
  letter-spacing: 0.1px;
  line-height: 24px;
  font-size: 14px;
}
#footer .content-read * {
  color: #303030;
}
#footer .content-read::-webkit-scrollbar {
  width: 6px;
  border: 0;
}

#footer .content-read::-webkit-scrollbar-thumb {
  background-color: #175eef;
}

#footer .modal-open .modal {
  overflow: hidden !important;
}
/* 
 body.modal-open {
  overflow: visible !important;
} */
/* body.modal-open {
  overflow: hidden;
} */

#footer .table td,
#footer .table th {
  border: 1px solid #dee2e6 !important;
  text-align: center !important;
}

#footer .fw-600 {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  #footer .bg-footer {
    background: #19192c !important;
  }
  #footer {
    margin: 0rem !important;
  }
  #footer .modal-content {
    width: 100vw !important;
    height: 100vh !important;
  }
  #footer .modal-header .close {
    color: #fff !important;
    padding: 2rem;
  }
}
