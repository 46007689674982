/* Make the image fully responsive */
#layout-container .carousel-inner img {
  width: 30%;
  height: 30%;
}
/* #layout-container .carousel-indicators li::before,
#layout-container .carousel-indicators li::after {
  display: block;
  content: '';

  width: 25.4rem;
  margin: 0 1rem;
  transform: translate(-26.03rem, 0.7rem);

  background-color: white;
} */

#layout-container .carousel-indicators li:nth-child(1):after {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(1):before {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(2):after {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(3):after {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(4):after {
  display: none;
}

#layout-container .carousel-indicators {
  margin-left: 0;
  margin-right: 0;
  background-color: #165eef !important;
  height: 20%;
  bottom: 0%;
  top: 75%;
}

#layout-container .carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 1rem !important;
  height: 1rem !important;

  margin-top: 3rem !important;
  margin-right: 0.5rem !important;

  /* margin-right: 15rem;
  margin-left: 10rem; */
  text-indent: -999px;
  border-radius: 50%;
  cursor: pointer;
  background-clip: border-box !important;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  transition: opacity 0.6s ease;
}
/* #layout-container .carousel-indicators li.active::before,
#layout-container .carousel-indicators li.active::after {
  background-color: blue;
  opacity: 1;
} */

#layout-container .center-mob {
  display: flex;
  justify-content: center;
  color: #ffffff;
  position: relative;
  line-height: 200% !important;
}
#layout-container .center-mob p {
  font-weight: 700;
  padding-top: 2rem;
}

#layout-container .f-800 {
  font-weight: 800 !important;
}

#layout-container .carousel-indicators li:active {
  background-color: #ffff !important;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #layout-container .carousel-indicators li {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  #layout-container .carousel-indicators li::before,
  #layout-container .carousel-indicators li::after {
    margin-right: 0rem;
    margin-left: 0rem;
    width: 0%;
  }
}
#layout-container .circle {
  height: 35px;
  width: 35px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
#layout-container .indexedStep {
  color: white;
  width: 25px;
  height: 25px;
  font-size: 12px;
  background-color: #ffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#layout-container .indexedStep.accomplished {
  background-color: blue;
}
#layout-container .RSPBprogressBar {
  left: 13%;
  top: 100px !important;
}

#layout-container .h-700 {
  height: 700px;
}
#layout-container .centerAligned {
  display: flex;
  justify-content: center;
}
#layout-container .RSPBprogressBar {
  height: 5px !important;
}
#layout-container .RSPBprogressBar .RSPBstep {
  transform: none !important;
}
#layout-container .centerText {
  text-align: center;
  color: #fff;
}

#layout-container .max-wid {
  max-width: 57rem;
}

#layout-container .fw-700 {
  font-weight: 700;
}
.slide___3-Nqo {
  height: 20rem !important;
}
#layout-container .careerIcon {
  height: 25rem !important;
  width: 25rem !important;
}
