@import url(https://code.highcharts.com/css/stocktools/gui.css);
@import url(https://code.highcharts.com/css/annotations/popup.css);
#footer .modal-content {
  width: 95vw;
  height: 93vh;
  background: #19192c;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
#footer .modal-header {
  border-bottom: none !important;
}
#footer .sidebar-sticky {
  text-align: left !important;
  padding-top: 0.4rem;
  font-weight: 600;
}
#footer .sidebar-sticky a {
  color: #fff;
  opacity: 0.2;
  letter-spacing: 1px;
  font-size: 14px;
  letter-spacing: 0.2px;
  padding: 1rem 0 0.8rem 2rem;
}
#footer .sidebar-sticky a.active {
  opacity: 1;
}

#footer .bg-blue {
  color: #007bff !important;
}

#footer .content-read-header h2 {
  background: #19192c;
  padding: 1rem 3rem;
  color: #ffff;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.8px;
}
#footer .content-read h3 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.8px;
}
#footer .sidebar-sticky a:hover,
#footer .sidebar-sticky a:focus {
  cursor: pointer !important;
}
#footer .content-read {
  background: #ffffff;
  color: #000;
  height: 70vh !important;
  overflow-y: scroll !important;
}
#footer .content-read p {
  letter-spacing: 0.1px;
  line-height: 24px;
  font-size: 14px;
}
#footer .content-read * {
  color: #303030;
}
#footer .content-read::-webkit-scrollbar {
  width: 6px;
  border: 0;
}

#footer .content-read::-webkit-scrollbar-thumb {
  background-color: #175eef;
}

#footer .modal-open .modal {
  overflow: hidden !important;
}
/* 
 body.modal-open {
  overflow: visible !important;
} */
/* body.modal-open {
  overflow: hidden;
} */

#footer .table td,
#footer .table th {
  border: 1px solid #dee2e6 !important;
  text-align: center !important;
}

#footer .fw-600 {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  #footer .bg-footer {
    background: #19192c !important;
  }
  #footer {
    margin: 0rem !important;
  }
  #footer .modal-content {
    width: 100vw !important;
    height: 100vh !important;
  }
  #footer .modal-header .close {
    color: #fff !important;
    padding: 2rem;
  }
}

/* Make the image fully responsive */
#layout-container .carousel-inner img {
  width: 30%;
  height: 30%;
}
/* #layout-container .carousel-indicators li::before,
#layout-container .carousel-indicators li::after {
  display: block;
  content: '';

  width: 25.4rem;
  margin: 0 1rem;
  transform: translate(-26.03rem, 0.7rem);

  background-color: white;
} */

#layout-container .carousel-indicators li:nth-child(1):after {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(1):before {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(2):after {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(3):after {
  display: none;
}
#layout-container .carousel-indicators li:nth-child(4):after {
  display: none;
}

#layout-container .carousel-indicators {
  margin-left: 0;
  margin-right: 0;
  background-color: #165eef !important;
  height: 20%;
  bottom: 0%;
  top: 75%;
}

#layout-container .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 1rem !important;
  height: 1rem !important;

  margin-top: 3rem !important;
  margin-right: 0.5rem !important;

  /* margin-right: 15rem;
  margin-left: 10rem; */
  text-indent: -999px;
  border-radius: 50%;
  cursor: pointer;
  background-clip: border-box !important;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  transition: opacity 0.6s ease;
}
/* #layout-container .carousel-indicators li.active::before,
#layout-container .carousel-indicators li.active::after {
  background-color: blue;
  opacity: 1;
} */

#layout-container .center-mob {
  display: flex;
  justify-content: center;
  color: #ffffff;
  position: relative;
  line-height: 200% !important;
}
#layout-container .center-mob p {
  font-weight: 700;
  padding-top: 2rem;
}

#layout-container .f-800 {
  font-weight: 800 !important;
}

#layout-container .carousel-indicators li:active {
  background-color: #ffff !important;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #layout-container .carousel-indicators li {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  #layout-container .carousel-indicators li::before,
  #layout-container .carousel-indicators li::after {
    margin-right: 0rem;
    margin-left: 0rem;
    width: 0%;
  }
}
#layout-container .circle {
  height: 35px;
  width: 35px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
#layout-container .indexedStep {
  color: white;
  width: 25px;
  height: 25px;
  font-size: 12px;
  background-color: #ffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#layout-container .indexedStep.accomplished {
  background-color: blue;
}
#layout-container .RSPBprogressBar {
  left: 13%;
  top: 100px !important;
}

#layout-container .h-700 {
  height: 700px;
}
#layout-container .centerAligned {
  display: flex;
  justify-content: center;
}
#layout-container .RSPBprogressBar {
  height: 5px !important;
}
#layout-container .RSPBprogressBar .RSPBstep {
  -webkit-transform: none !important;
          transform: none !important;
}
#layout-container .centerText {
  text-align: center;
  color: #fff;
}

#layout-container .max-wid {
  max-width: 57rem;
}

#layout-container .fw-700 {
  font-weight: 700;
}
.slide___3-Nqo {
  height: 20rem !important;
}
#layout-container .careerIcon {
  height: 25rem !important;
  width: 25rem !important;
}

.TVChartContainer {
  height: calc(54vh - 80px);
}

rect.highcharts-background {
  fill: #151525;
}

path.highcharts-axis-line {
  stroke: #151525;
}

path.highcharts-tick {
  stroke: #151525;
}

path.highcharts-grid-line {
  stroke: #272763;
}

html,
body {
  height: 100%;
}

h1,
p {
  /* font-family: Lato; */
}

button {
  background: cornflowerblue;
}

.chart-overflow {
  overflow: visible !important;
}

.DayPickerInput input {
  background-color: #10101c;
}

.DayPickerInput-Overlay {
  background: #10101c;
}

/* Conflict with Bootstrap, not needed after v7.0.1 */
.highcharts-bindings-wrapper * {
  box-sizing: content-box;
}

